// uploadImageSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addBackgroundAction,
  addBackgroundColorAction,
  addBackgroundSelectedImgAction,
  addConverterImgAction,
  addResizeImgAction,
  getImages,
  removeBackgroundAction,
} from "./UploadFileAction";
const baseUrl = process.env.NEXT_PUBLIC_API_URL;

const initialState = {
  image: null,
  resultImage: null,
  bgToAdd: null,
  status: "idle",
  error: null,
  images: [],
};

export const UploadFile = createAsyncThunk("image/uploadFile", async () => {
  const response = await setUploadFile();
  return response;
});

export const removeBackground = createAsyncThunk(
  "image/removeBackground",
  async (formData, thunkAPI) => {
    try {
      const response = await removeBackgroundAction(formData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const addBackground = createAsyncThunk(
  "image/addBackground",
  async (formData, thunkAPI) => {
    try {
      const response = await addBackgroundAction(formData);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const addBackgroundColor = createAsyncThunk(
  "image/addBackgroundColor",
  async (formData, thunkAPI) => {
    try {
      const response = await addBackgroundColorAction(formData);
 
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const addBackgroundSelectedImg = createAsyncThunk(
  "image/addBackgroundSelected",
  async (formData, thunkAPI) => {
    try {
      const response = await addBackgroundSelectedImgAction(formData);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getBackgroundImages = createAsyncThunk(
  "image/getBackgrounds",
  async (thunkAPI) => {
    try {
      const response = await getImages();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const resizeImage = createAsyncThunk(
  "image/resizeImage",
  async (formData, thunkAPI) => {
    try {
      const response = await addResizeImgAction(formData);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const ConvertFile = createAsyncThunk(
  "image/ConvertFile",
  async (formData, thunkAPI) => {
    try {
      const response = await addConverterImgAction(formData);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const uploadImageSlice = createSlice({
  name: "uploadImage",
  initialState: initialState,
  reducers: {
    setUploadedImage: (state, action) => {
      state.image = action.payload === null ? null : action.payload;
    },
    //upload img
    selectBackgroundImgtoAdd: (state, action) => {
      state.bgToAdd = action.payload === null ? null : action.payload;
    },
    //select an image
    selectBackgroundImg: (state, action) => {
      state.selectedbgToAdd = action.payload === null ? null : action.payload;
      state.colorToAdd=null
    },
    //select a color as a bg
    selectBackgroundColor: (state, action) => {
      state.colorToAdd = action.payload === null ? null : action.payload;
      state.selectedbgToAdd = null
    },
    resetUploadedFile: (state) => {
      state.image=null,
      state.resultImage=null,
      state.bgToAdd=null,
      state.status="idle",
      state.error=null,
      state.images= state.images
    },
    // Optional: handle status and error updates if needed
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeBackground.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeBackground.fulfilled, (state, action) => {
        state.status = "succeeded";
         state.resultImage = action.payload?.code==='2000'   ? baseUrl + action.payload.data.result : null;
      })
      .addCase(removeBackground.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addBackground.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addBackground.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.resultImage = action.payload?.code==='2000'   ? baseUrl + action.payload.data.result : null;
      })
      .addCase(addBackground.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(addBackgroundColor.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addBackgroundColor.fulfilled, (state, action) => {

         state.status = "succeeded";
        state.resultImage = action.payload?.code==='2000' ? baseUrl + action.payload.data.result : null;
      })

      .addCase(addBackgroundColor.rejected, (state, action) => {
        state.status = "failed";
        state.resultImage = action.payload?.code==='2000'   ? baseUrl + action.payload.data.result : null;
      })
      .addCase(addBackgroundSelectedImg.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addBackgroundSelectedImg.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.resultImage = action.payload?.code==='2000'   ? baseUrl + action.payload.data.result : null;
      })
      .addCase(addBackgroundSelectedImg.rejected, (state, action) => {
        state.status = "failed";
      })

      .addCase(getBackgroundImages.fulfilled, (state, action) => {
        state.images = action.payload;
      })
      .addCase(resizeImage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(resizeImage.fulfilled, (state, action) => {
        state.status = "succeeded";
         state.resultImage = action.payload?.code==='2000'   ? baseUrl + action.payload.data.result : null;
      })
      .addCase(resizeImage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(ConvertFile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ConvertFile.fulfilled, (state, action) => {
        state.status = "succeeded";
         state.resultImage = action.payload?.code==='2000'   ? baseUrl + action.payload.data.result : null;
      })
      .addCase(ConvertFile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const {
  setUploadedImage,
  setRemoveBackground,
  resetUploadedFile,
  setStatus,
  setError,
  selectBackgroundColor,
  selectBackgroundImg,
  selectBackgroundImgtoAdd,
} = uploadImageSlice.actions;

// Selector
export const selectUploadedFile = (state) => state.uploadImage?.image;
export const selectStatus = (state) => state.uploadImage?.status;
export const selectError = (state) => state.uploadImage?.error;
export const selecUploadedImage = (state) => state.uploadImage?.resultImage;
export const selecBgToAdd = (state) => state.uploadImage?.bgToAdd;
export const selectedColor = (state) => state.uploadImage?.colorToAdd;
export const selectedImage = (state) => state.uploadImage?.selectedbgToAdd;
export const bgImages = (state) => state.uploadImage?.images?.backgrounds;

export default uploadImageSlice.reducer;
