import Image from "next/image";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Link from "next/link";
import CustomAlertModal from "../components/CustomAlertModal";

const LoginLayout = ({ children, img }) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <main className="login-layout">
      <div>
        <Container
          fluid
          className="d-flex justify-content-center align-items-center min-vh-100"
        >
          <Row className="login-row">
            {!isMobile && (
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="side-img cursor-pointer">
                  <Link href="/" passHref>
                    <span>
                      <Image
                        className="login-image"
                        src={img}
                        alt="Feature-icon"
                        priority={true}
                      />
                    </span>
                  </Link>
                </div>
              </Col>
            )}
            <Col
              md={isMobile ? 12 : 6} // Occupy full width on mobile
              className="d-flex justify-content-center align-items-center"
            >
              <div className="login-content">{children}</div>
            </Col>
          </Row>
        </Container>
      </div>
    </main>
  );
};
export default LoginLayout;
