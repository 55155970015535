import axios from "axios";
import { axiosInstance } from "../../config/Axios";
import { dataAxios } from "../../config/dataAxios";
const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const AUTH = API_URL + "/login";
export const SIGNUP =API_URL+"/add_user"
export const CHANGE_PASSWORD =API_URL+"/change_password"
export const USER =API_URL+"/user"
export const GET_PROFILE =API_URL+"/profile"
export const GET_VERIF_EMAIL =API_URL+"/verify_email"
export const FORGOT_PASSWORD=API_URL+"/forgot_password"
export const RESET_PASSWORD=API_URL+"/reset_password"
export const CONTACT_US=API_URL+"/contact"
export function Login(data) {
  return axiosInstance
  
    .post(AUTH,data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}


export function RegisterUser(data){
  return axiosInstance
  .post(SIGNUP,data)
  .then((res)=>{
    return res;
  })
  .catch((err)=> {
    return err;
  })
}



export function changePassword(data){
  return dataAxios
  .put(CHANGE_PASSWORD,data)
  .then((res)=>{
    return res;
  })
  .catch((err)=> {
     return err.response;
  })
}


export function updateprofile(data){
  return dataAxios
  .patch(USER,data)
  .then((res)=>{
    return res;
  })
  .catch((err)=> {
     return err.response;
  })
}


export function getUserprofile(){
  return dataAxios
  .get(GET_PROFILE,{})
  .then((res)=>{
    return res;
  })
  .catch((err)=> {
     return err.response;
  })
}


export function verifEmail(id){
  return axios
  .get(GET_VERIF_EMAIL+"/"+id,{})
  .then((res)=>{
    return res;
  })
  .catch((err)=> {
     return err.response;
  })
}

export function ForgotPassword(data){
  return axios
  .post(FORGOT_PASSWORD,data)
  .then((res)=>{
    return res;
  })
  .catch((err)=> {
    return err;
  })
}

export function ResetPassword(data){
  return axios
  .post(RESET_PASSWORD,data)
  .then((res)=>{
    return res;
  })
  .catch((err)=> {
    return err;
  })
} 
export function Contact(data){
  return axios
  .post(CONTACT_US,data)
  .then((res)=>{
    return res;
  })
  .catch((err)=> {
    return err;
  })
} 