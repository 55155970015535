import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { changePassword, Contact, ForgotPassword, getUserprofile, Login, RegisterUser, ResetPassword, updateprofile } from "./UserAction";

const initialState = {
  user: null,
  isauth: false,
  authstatus: "",
  autherror: {
    iserror: false,
    message: "",
  },
};

export const login = createAsyncThunk("/login", async (data) => {
  const response = await Login(data);
  return response.data;
});
export const contactus = createAsyncThunk("/contact-us", async (data) => {
  const response = await Contact(data);
  return response.data;
});

export const register =createAsyncThunk("/register", async(data)=> {

        const response = await RegisterUser(data);
        return response.data
})

export const changeUserPassword =createAsyncThunk("/change-password", async(data)=> {
  const response = await changePassword(data);
  return response.data
})


 
export const updateUserprofile =createAsyncThunk("/update-profile", async(data)=> {
  const response = await updateprofile(data);
  return response.data
})

export const fetchUserProfile =createAsyncThunk("/get-profile", async(data)=> {
  const response = await getUserprofile(data);
  return response.data
})
export const forgotPassword =createAsyncThunk("/forgot-password", async(data)=> {
  const response = await ForgotPassword(data);
  return response.data
})
export const resetPassword =createAsyncThunk("/reset-password", async(data)=> {
  const response = await ResetPassword(data);
  return response.data
})
export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.isauth = false;
      state.authstatus = "";
      localStorage.clear();
    },
    logg: (state) => {
      state.isauth = true;
      // state.user = action.payload?.data?.user;
      state.autherror.iserror = false;
      state.autherror.message = "";
      state.authstatus = "success";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      if (action?.payload?.data) {
        localStorage.setItem("access_token", action.payload.data.access_token);
        state.isauth = true;
        state.user = action.payload?.data?.user;
        state.autherror.iserror = false;
        state.autherror.message = "";
        state.authstatus = "success";
      } else {
        state.autherror.iserror = true;
        state.autherror.message = "invalid credentials";
        state.authstatus = "failure";
      }
    });
    builder.addCase(register.fulfilled, (state, action) => {
       });
      builder.addCase(register.rejected, (state, action) => {
       });

      builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
         state.user = action.payload;
      });

      builder.addCase(updateUserprofile.fulfilled, (state, action) => {
         state.user = action.payload.data;
      });
  },
});
export const { logout,logg } = userSlice.actions;
export const selectauthstatus = (state) => state.user.authstatus;
export const selectisauth = (state) => state.user?.isauth;
export const selectautherror = (state) => state.user?.autherror;
export const selectautheduser = (state) => state.user?.user;

export const  selectuserProfile = (state)=>state.user.user?.data? state.user.user?.data:state.user.user ;
export default userSlice.reducer;
