// src/Redux/LogoutModal/modalSlice.js
import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'LogoutModal',
  initialState: {
    visible: false,
    message: '',
    color: '',
  },
  reducers: {
    showLogoutModal: (state, action) => {
      state.visible = true;
      state.message = action.payload.message;
      state.title = action.payload.title;
      state.color = action.payload.color;
      
    },
    hideLogoutModal: (state) => {
      state.visible = false;
      state.message = '';
      state.title ='';
      state.color = '';
    },
  },
});

export const { showLogoutModal, hideLogoutModal } = modalSlice.actions;
export default modalSlice.reducer;
