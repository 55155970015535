import axios from 'axios';
  
// Function to get the current token from localStorage
const getToken = () => localStorage.getItem("access_token");

 // Function to create an Axios instance with interceptors
const createDataAxios = () => {
  const dataAxios = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Request interceptor to add the Authorization header
  dataAxios.interceptors.request.use(
    config => {
      const token = getToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(error)
  );

  return dataAxios;
};

// Create and export the Axios instance
export const dataAxios = createDataAxios();
