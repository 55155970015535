const axios = require('axios');

// Créer une instance d'axios
const axiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
        // Authorization: `Bearer ${token}`,
    },
});

// Configurer un intercepteur pour les requêtes
axiosInstance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// Exporter l'instance d'axios avec CommonJS
module.exports = { axiosInstance };
