import Image from "next/image";
import phoneicon from "../../src/assets/images/icons/PhoneFilled.png";
import messageicon from "../../src/assets/images/icons/MessageIcon.png";
import Link from "next/link";
import SmokeEffect from "../components/smokeEffect";
import { useEffect, useState } from "react";

const Footer = ({ logo, navs, icons, powredby }) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Détection du mode mobile
    };

    // Écouter les changements de taille d'écran
    window.addEventListener("resize", handleResize);

    // Initialiser la valeur au chargement de la page
    handleResize();

    // Nettoyage de l'événement à la destruction du composant
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="footer-container">
      
    <div className="footer">
    <SmokeEffect></SmokeEffect>
      <div className="footer-section logo-description">
        <div className="logo-content">
          <Image src={logo} alt="logo image" />
        </div>
        <div className="footer-poweredby">
          <div dangerouslySetInnerHTML={{ __html: powredby }} />
        </div>{" "}
        <div className="footer-section icons-container">
          {icons.map((icon, index) => (
            <div key={index} className="icon-wrapper">
              <Link href={icon.url}>
                <Image src={icon.img} alt="icone" />
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="footer-links">
        <div className="footer-pages">
          <span className="footer-pages-title">Pages</span>
          <div className="footer-pages-items">
            {isMobile ? (
              // Liste statique en mode mobile
              <>
                <div className="footer-pages-link py-1">
                </div>
                <div className="footer-pages-link">
                  <Link href="/remove-background">
                    <p className="navitem-section">Try Now</p>
                  </Link>
                </div>
                <div className="footer-pages-link">
                  <Link href="/tarifs">
                    <p className="navitem-section">Pricing</p>
                  </Link>
                </div>
              </>
            ) : (
              // Liste dynamique en mode desktop
              navs.map((navitem, index) => (
                <div key={index} className="footer-pages-link">
                  <Link href={navitem.url}>
                    <p className="navitem-section">{navitem.name}</p>
                  </Link>
                </div>
              ))
            )}
          </div>
        </div>

        <div className="footer-contact">
          <span className="footer-pages-title">Contact</span>
          <div className="footer-pages-items">
            

            <div className="navitem-section">
              <span className="footer-contact-item img-icon-footer">
                <Image src={messageicon} alt="icone" />
              </span>
              <a className="a-href-no-style" href="mailto:contact@pixeloop.io">contact@pixeloop.io</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Footer;
