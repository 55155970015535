  const { default: axios } = require('axios');
  const { axiosInstance } = require('../../config/Axios');

  const API_URL = process.env.NEXT_PUBLIC_API_URL;

  // Définir les constantes
  const BLOGLIST = API_URL + "/blog";
  const BLOGBYSLUG = API_URL + "/blogs";

  // Définir les fonctions
  function GetAllBlog(page, per_page) {
    return axios
      .get(BLOGLIST, {
        params: {
          per_page: per_page,
          page: page,
        },
      })
      .then((res) => {
        return res; 
      })
      .catch((err) => {
        console.error("Error in GetAllBlog:", err.message);
        return { data: { data: [] }};
      });
  }
  

  function GetBlogById(id) {
    return axiosInstance
      .get(BLOGLIST + "/" + id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  function GetBlogBySlug(slug) {
    return axios
      .get(BLOGBYSLUG + "/" + slug)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
  module.exports = {
    BLOGLIST,
    BLOGBYSLUG,
    GetAllBlog,
    GetBlogById,
    GetBlogBySlug,
  };
