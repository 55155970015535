import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ChooseSubscription, GetAllSubscription, GetUserSubscriptions } from "./SubscriptionAction";

const initialState = {
  subscription: [],
  subscriptions: [],
  success:'error'
};

export const getallsubscriptions = createAsyncThunk(
  "PRICING lIST",
  async () => {
    const response = await GetAllSubscription();
    return response.data;
  }
);
export const getuserSubscriptions = createAsyncThunk(
  "USER_SUBSCRIPTIONS",
  async ({ page, per_page }) => {
    const response = await GetUserSubscriptions(page, per_page);
     return response.data;
  }
);

export const addUserSubscription = createAsyncThunk(
  "CHOOSE SUBSCRIPTION",
  async(subscriptionData)=>{
    const response=await ChooseSubscription(subscriptionData);
    return response
  }
)

export const SubscriptionSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getallsubscriptions.fulfilled, (state, action) => {
      state.subscriptions = action?.payload?.data;
    });

    builder.addCase(getuserSubscriptions.fulfilled, (state, action) => {
       state.subscription = action?.payload;
    });

    builder.addCase(addUserSubscription.fulfilled , (state,action)=>{
      state.success='success'
    })
  },
});

export default SubscriptionSlice.reducer;
export const pricinglist = (state) => state.subscriptions.subscriptions;
export const userSubscriptions = (state) =>  state.subscriptions.subscription;
