// src/Redux/alert/alertSlice.js
import { createSlice } from '@reduxjs/toolkit';

const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    visible: false,
    message: '',
    color: '',
    timeout: 3000,
  },
  reducers: {
    showAlert: (state, action) => {
      state.visible = true;
      state.message = action.payload.message;
      state.color = action.payload.color;
      state.timeout = action.payload.timeout;
    },
    hideAlert: (state) => {
      state.visible = false;
      state.message = '';
      state.color = '';
      state.timeout = 3000;
    },
  },
});

export const { showAlert, hideAlert } = alertSlice.actions;
export default alertSlice.reducer;
