import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { logout } from "../Redux/user/UserSlice";
import { hideLogoutModal } from "../Redux/alert/modalLogoutSlice";
import Link from "next/link";
import { hideAlert } from "../Redux/alert/BannerSlice";

const CustomAlertModal = () => {
  const { message, visible ,title} = useSelector((state) => state.logoutModal);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
    dispatch(hideAlert())
    dispatch(hideLogoutModal());
  };
  return (
    <Modal style={{ background: "white" }} isOpen={visible} >
       <Link href="/login" passHref><ModalHeader toggle={handleLogout}>{title}</ModalHeader></Link>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Link href="/login" passHref>
          <Button color="primary" onClick={handleLogout}>
            OK
          </Button>
        </Link>
      </ModalFooter>
    </Modal>
  );
};

export default CustomAlertModal;
