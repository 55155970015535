const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
export default {
  title: 'Pixeloop - AI-Powered Image Editing',
  description: 'Discover our powerful tool for removing and adding backgrounds, resizing images, and integrating APIs with Prestashop. Streamline your workflow and enhance your visuals effortlessly, tailored for businesses in Tunisia.',
  keywords: 'background removal, image resizing, Prestashop API integration, AI tools, Tunisia image optimization',
  openGraph: {
    type: 'website',
    locale: 'en_US',  
    url: BASE_URL,
    site_name: 'Pixeloop',
    title: 'Pixeloop - Effortless AI Image Editing for Tunisian Businesses',
    description: 'Optimize your images with Pixeloop\'s AI-powered tools, designed for the needs of businesses in Tunisia. Remove backgrounds, resize, and integrate seamlessly with Prestashop for enhanced visuals.',
  
  },
};
