// src/Redux/alert/alertSlice.js
import { createSlice } from '@reduxjs/toolkit';

const bannerSlice = createSlice({
  name: 'banner',
  initialState: {
    visible: false,
    message: '',
    color: '',
    autoDismiss: true,
    alerts: [],


  },
  reducers: {
    showAlert: (state, action) => {
      state.visible = true;
      state.message = action.payload.message;
      state.color = action.payload.color;
      state.autoDismiss= action.payload.autoDismiss;
     
      
    },
    hideAlert: (state) => {
      state.visible = false;
      state.message = '';
      state.color = '';
      state.autoDismiss=true;
      
    },
    clearAlerts: (state) => {
      state.visible = false;
      state.message=""
    },
  },
});

export const { showAlert, hideAlert,clearAlerts } = bannerSlice.actions;
export default bannerSlice.reducer;
