import axios from "axios";
import Router from "next/router";
import { dataAxios } from "../../config/dataAxios";
import { store } from "../../config/Store";
import { logout } from "../user/UserSlice";
const API_URL = process.env.NEXT_PUBLIC_API_URL;

const GET_IMAGES = API_URL + "/backgrounds";
export async function removeBackgroundAction(formData) {
  const baseUrl = process.env.NEXT_PUBLIC_API_URL;
  const token = localStorage.getItem("access_token");
  try {
    const response = await axios.post(
      baseUrl + "/remove_background",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 422)
    ) {
      store.dispatch(logout());    }
    throw error; // Rethrow the error for further handling if needed
  }
}

export async function addBackgroundAction(formData) {
  const baseUrl = process.env.NEXT_PUBLIC_API_URL;
  const token = localStorage.getItem("access_token");

  try {
    const response = await axios.post(baseUrl + "/add_background", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 422)
    ) {
      store.dispatch(logout());
    }
    throw error; // Rethrow the error for further handling if needed
  }
}

export async function addBackgroundColorAction(formData) {
  const baseUrl = process.env.NEXT_PUBLIC_API_URL;
  const token = localStorage.getItem("access_token");
  try {
    const response = await axios.post(
      baseUrl + "/add_background_color",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 422)
    ) {
      Router.push("/login"); // Redirect to home page
    }
    throw error; // Rethrow the error for further handling if needed
  }
}

export async function addBackgroundSelectedImgAction(formData) {
  const baseUrl = process.env.NEXT_PUBLIC_API_URL;
  const token = localStorage.getItem("access_token");

  try {
    const response = await axios.post(
      baseUrl + "/choose_background",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 422)
    ) {
      Router.push("/login"); // Redirect to home page
    }
    throw error; // Rethrow the error for further handling if needed
  }
}

export function getImages() {
  return dataAxios
    .get(GET_IMAGES, {})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export async function addResizeImgAction(formData) {
  const baseUrl = process.env.NEXT_PUBLIC_API_URL;
  const token = localStorage.getItem("access_token");

  try {
    const response = await axios.post(baseUrl + "/resize_image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 422)
    ) {
      store.dispatch(logout());
    }
    throw error; 
  }
}

export async function addConverterImgAction(formData) {
  const baseUrl = process.env.NEXT_PUBLIC_API_URL;
  const token = localStorage.getItem("access_token");

  try {
    const response = await axios.post(baseUrl + "/convert", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 422)
    ) {
      store.dispatch(logout());
    }
    throw error; 
  }
}