import React, { useEffect } from "react";
import { Container } from "reactstrap";
import Header from "./header/Header";
import Sidebar from "./sidebars/vertical/Sidebar";
import Footer from "./Footer";

import logo from "../assets/images/logos/pixeloopWhiteLogo.svg";
import linkdin from "../assets/images/icons/linkedin.svg";
import fb from "../assets/images/icons/facebook.svg";
import instagram from "../assets/images/icons/instagram.svg";

import CustomAlertModal from "../components/CustomAlertModal";
import { useDispatch } from "react-redux";
import { fetchUserProfile, logout } from "../Redux/user/UserSlice";
import { showLogoutModal } from "../Redux/alert/modalLogoutSlice";
import { useRouter } from "next/router";

const FullLayout = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const router = useRouter(); 
  const  { id } = router.query;
  const showMobilemenu = () => {
    setOpen(!open);
  };
  const navs = [
    // { name: "Home" ,url:'/'},
    { name: "Try Now" ,url:'/remove-background'},
    { name: "Pricing"  ,url:'/tarifs'},
    { name: "Blog"  ,url:'/blog'},
    { name: "Contact us"  ,url:'/contact-us'},
    { name: "FAQ"  ,url:'/faq'},
  ];

  const icons = [{ img: fb ,url:'https://www.facebook.com/profile.php?id=61564995621975'}, { img: instagram ,url:'https://www.instagram.com/pixeloopai/' }, { img: linkdin ,url:'https://www.linkedin.com/company/gorillaai/' }];
 
  const dispatch = useDispatch()
  // Function to get the current token from localStorage
  // const token =localStorage.getItem("access_token");
  useEffect(() => {
    const token =localStorage.getItem("access_token");

    if (token) {
      dispatch(fetchUserProfile()).then((response) => {
        if (
          response.payload?.code === "4002" ||
          response.payload?.code === "4004"
        ) {
          dispatch(logout());
        }
      });
    }
  }, [dispatch]);

 
 
  return (
    <main id={id || router.route.replaceAll('/', '')}>
      <div className="pageWrapper d-md-block d-lg-flex">
      <CustomAlertModal/>
        
          {/********header**********/}
        <Header showMobmenu={() => showMobilemenu()} />
        {/********Content Area**********/}
        <div className="contentArea">
          {/********Middle Content**********/}
          <Container className="mt-md-4" fluid>
            <div>{children}</div>
          </Container>
        </div>
        <div></div>
      </div>
      <Footer
        logo={logo}
        navs={navs}
        icons={icons}
        powredby={`<div>© 2024 pixeloop - AI Background Removal. Simplify your image editing with our advanced technology.<br />Powered by <a href="https://www.trustdev.info" target="_blank" rel="noopener noreferrer" class="no-style-link">TrustDev</a> for a better web.</div>`}
        />
    </main>
  );
};

export default FullLayout;
