import FullLayout from "../src/layouts/FullLayout";
import "../styles/style.scss";
import { DefaultSeo, NextSeo } from "next-seo";
import SEO from "../next-seo.config";
import { Provider } from "react-redux";
import { store, } from "../src/config/Store";
import LoginLayout from "../src/layouts/LoginLayout";
import loginImg from "../src/assets/images/background/signinImg@.svg";
import registerImg from "../src/assets/images/background/signupImg@.svg";
import forgotpasswordImg from "../src/assets/images/background/forgotpassword.png";
import { useRouter } from "next/router";
import { isAuthenticated } from "../src/helpers/auth";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { GoogleAnalytics } from "nextjs-google-analytics";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const privateRoutes = [
      "/profile",
      "/account-details",
      "/subscription-details",
      "/subscription-history",
      "/change-password",
    ];
    const publicRoutes = ["/login", "/register", "forgot-password"];
    const profileRoutes = [
      "/account-details",
      "/subscription-details",
      "/subscription-history",
      "/change-password",
    ];

    if (profileRoutes.includes(router.pathname) && !isMobile) {
      router.push("/profile");
    }
    if (privateRoutes.includes(router.pathname) && !isAuthenticated()) {
      router.push("/login");
    }
    if (publicRoutes.includes(router.pathname) && isAuthenticated()) {
      router.push("/");
    }
  }, [isMobile, router]);

  const getLayout = (Component) => {
    if (
      Component.layout === "login" ||
      Component.layout === "register" ||
      Component.layout === "forget-password"
    ) {
      return LoginLayout;
    }
    return FullLayout;
  };
  const Layout = getLayout(Component);
  const imgSrc =
    Component.layout === "login"
      ? loginImg
      : Component.layout === "register"
      ? registerImg
      : Component.layout === "forget-password"
      ? forgotpasswordImg
      : forgotpasswordImg;

  return (
    <>
      {/* Rendre le SEO personnalisé avant PersistGate */}
      {Component.seo ? <NextSeo {...Component.seo} /> : <DefaultSeo {...SEO} />}

      {/* Google Analytics */}
      <GoogleAnalytics trackPageViews={{ ignoreHashChange: true }} />

      {/* Provider Redux avec PersistGate */}
      <Provider store={store}>
        <Layout img={imgSrc}>
          <Component {...pageProps} />
        </Layout>
      </Provider>
    </>
  );
}

export default MyApp;
